import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { addToCart } from "../actions";
import { Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  button: {
    color: theme.palette.text.alt,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
    padding: "7px 10px",
    zIndex: 40,
    "@media (max-width: 700px)": {
      padding: "7px 5px",
    },
   
  },
  text: {
    fontSize: 14
    // "@media (max-width: 768px)": {
    //   fontSize: 16    
    // },
  

  },

  hideText: {
    "@media (max-width: 500px)": {
      display: "none"
    },
  },
  genericIcon: {
    marginRight: 10,
    "@media (max-width: 700px)": {
      marginRight: 3,
    }
  },
  snackbarPaper: {
    zIndex: 100,
  },
  fullWidth:{
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },


}));

const AddToCartButton = props => {
  const classes = styles();

  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [duiten, setDuiten] = useState(props.userInformation.duiten);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleCloseSnackbarError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarError(false);
  };


  useEffect(() => {

    const calculateDuiten = () => {
      const totalCartPrice = props.cart.reduce((acc, item) => {
        const price = parseFloat(item.price);
        return acc + (isNaN(price) ? 0 : price);
      }, 0);

      const availableDuiten = parseFloat(props.userInformation.duiten);
      if (!isNaN(availableDuiten) && !isNaN(totalCartPrice)) {
        const remainingDuiten = availableDuiten - totalCartPrice;
        setDuiten(remainingDuiten);
      } 
    };

    calculateDuiten();
  }, [props.cart, props.userInformation.duiten]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Calculate total price in the cart
    const totalCartPrice = props.cart.reduce((acc, item) => {
      const price = parseFloat(item.price);
      return acc + (isNaN(price) ? 0 : price);
    }, 0);
    console.log(totalCartPrice, "totalCartPrice");
    console.log(duiten, "duiten");
    console.log(props.price,"props.price");
    console.log(props.userInformation.duiten, "props.userInformation.duiten");
    if (props.userInformation.duiten <= 0) {
      props.dispatch(
        addToCart(
          props.id,
          props.name,
          props.price,
          props.img,
          props.newImg ? props.newImg : false
        )
      );
      setOpenSnackbarError(true);
    } else if (props.price > duiten) {
      props.dispatch(
        addToCart(
          props.id,
          props.name,
          props.price,
          props.img,
          props.newImg ? props.newImg : false
        )
      );
      setOpenSnackbarError(true);
    }  else if (props.cart.filter(obj => obj.id === props.id).length >= 2) {
      setOpenAlert(true);
    } else {
      props.dispatch(
        addToCart(
          props.id,
          props.name,
          props.price,
          props.img,
          props.newImg ? props.newImg : false
        )
      );
      setOpen(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={`${props.isHideText && classes.fullWidth}`}>
      <Button
        size="large"
        className={`${classes.button} ${props.className} ${props.isHideText && classes.fullWidth}` }
        type="submit"
        
      >
        <ShoppingCartIcon className={classes.genericIcon} />
        <Typography className={`${classes.text} ${props.isHideText && classes.hideText }`}>
          <Translate>IN WINKELMANDJE</Translate>
        </Typography>
      </Button>

      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="warning">
          <Translate>
            Je kan maximaal 2 maal hetzelfde product aan je winkelmandje
            toevoegen
          </Translate>
        </Alert>
      </Snackbar>

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          <Translate>{props.name} is toegevoegd aan je winkelmandje</Translate>
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSnackbarError}
        autoHideDuration={60000}
        onClose={handleCloseSnackbarError}
        PaperProps={{ className: classes.snackbarPaper }}
      >
        <Alert severity="error" onClose={handleCloseSnackbarError}   >
          <Translate>{props.userInformation.firstName}, het aantal te besteden Duiten is 0 bekijk je winkelmandje</Translate>
        </Alert>
      </Snackbar>
    </form>
  );
};

const mapStateToProps = state => {
  const { cart, userInformation } = state;
  return { cart, userInformation };
};

export default connect(mapStateToProps)(AddToCartButton);
