import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { PRODUCT } from "../../queries-mutations/products";
import Grid from "@material-ui/core/Grid";
import ImageGallery from "react-image-gallery";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DuitenIcon from "../../components/DuitenIcon";
import parse from "html-react-parser";
import AddToCartButton from "../../components/AddToCartButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackButton from "../../components/BackButton";
import { Translate } from "react-auto-translate";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";


const styles = makeStyles((theme) => ({
  parentcontainer: {
    "@media (min-width: 956px)": {
    marginBottom: "20px" 
    }
  },

  previousContainer: {
    margin: "20px 10px",
  },
  galleryContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    "@media (max-width: 600px)": {
      // height: "50%",
    }
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    margin: "5px",
    marginBottom: "20px",
  },
  backgroundContainer: {
    backgroundColor: "#f0f0f0",
    textAlign: "left",
    padding: "15px 20px",
  },
  description: {
    marginBottom: "15px",
  },
  price: {
    display: "flex",
    alignItems: "center",
    marginLeft: "6px",
  },
  priceText: {
    // marginLeft: 10,
    marginLeft: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: 'center', // Centers items horizontally
     
  },
  button: {
    color: theme.palette.text.alt,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
    padding: "7px 20px",
    zIndex: 40,
  },
  previous: {
    textAlign: "Left",
    padding: 4,
    border: "1px solid rgba(0, 0, 0, 0.5)",
    width: 70,
    fontSize: 14,
    borderRadius: 4,
  },
  backIcon: {
    fontSize: 14,
  },
  cartButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 956px)": {
      // marginBottom: "20px",
    }
  },
  arrowBackIcon: {
    color: 'green',
    position: 'absolute',
    cursor: 'pointer',
    top: '50%',
    zIndex: 1,
  },
  arrowForwardIcon: {
    color: 'green',
    position: 'absolute',
    cursor: 'pointer',
    top: '50%',
    zIndex: 1,
  },
}));

export default function SingleProduct() {
  const classes = styles();
  let { id } = useParams();

  const { loading, error, data } = useQuery(PRODUCT, {
    variables: { id: id },
    skip: !id, // Skip query if id is not defined
  });

  const [arrowOffset, setArrowOffset] = useState(0);
  const [arrowHide, setArrowHide] = useState(false);
  const galleryContainerRef = useRef(null);
  const galleryRef = useRef(null);


  const updateArrowOffset = () => {
    console.log(galleryContainerRef.current && galleryRef.current, "difference");
    if (galleryContainerRef.current && galleryRef.current) {
      const containerWidth = galleryContainerRef.current.offsetWidth;
      const galleryWidth = galleryRef.current.offsetWidth;
      const difference = (containerWidth - galleryWidth) / 2;
      console.log(difference - 30 > 60 ? 60 : difference - 30, "difference");
      // setArrowOffset(difference - 30 > 60 ? 60 : difference - 30);
      setArrowHide(false)
    }
  };

  useEffect(() => {
    console.log("Product data fetched:", data);
    updateArrowOffset();
     window.scrollTo(0, 0);
  }, [data]);

  useEffect(() => {

    const updateHeaderHeight = () => {
      console.log("resize", "difference");
      updateArrowOffset();
    };
    window.addEventListener("resize", updateHeaderHeight);

    return () => window.removeEventListener("resize", updateHeaderHeight);
  }, []);

  const handleImageChange = (index) => {
    console.log("Current image index:", "difference");

    updateArrowOffset();
  };

  const handleBeforeSlide = () => {
    console.log("handleBeforeSlide", "difference");
    setArrowOffset(0)
    setArrowHide(true)
  };

  if (data) {
    const images = [];
    // eslint-disable-next-line array-callback-return
    data.product.images.map((image) => {
      images.push({
        original: "https://shop.zeghetmetduiten.nl/images/" + image.location,
      });
    });

    return (
      <Grid container className={classes.parentcontainer}>
        <Grid xs={0} md={1} xl={1} />
        <Grid xs={10} md={10} xl={10} className={classes.previousContainer}>
          <BackButton />
        </Grid>

        <Grid xs={0} md={1} xl={1} />
        <Grid xs={12} md={5} xl={5} className={classes.imageContainer}>
          <Container className={classes.galleryContainer} ref={galleryContainerRef}>
            <div ref={galleryRef}>
              <ImageGallery
                items={images}
                showThumbnails={false}
                showFullscreenButton={false}
                showPlayButton={false}
                showBullets={true}
                lazyLoad={true}
                showNav={true}
                renderLeftNav={(onClick, _disabled) => (

                  <ArrowBackIos className={classes.arrowBackIcon} style={{ left: arrowOffset > 0 ? `-${arrowOffset}px` : '0px', display: arrowHide ? 'none' : 'block' }} onClick={onClick} />
                )}
                renderRightNav={(onClick, _disabled) => (
                  <ArrowForwardIos className={classes.arrowForwardIcon} style={{ right: arrowOffset > 0 ? `-${arrowOffset}px` : '0px', display: arrowHide ? 'none' : 'block' }} onClick={onClick} />
                )}
                onSlide={handleImageChange}
                onBeforeSlide={handleBeforeSlide}
              />
            </div>
          </Container>
        </Grid>
        <Grid xs={12} md={5} xl={5} className={classes.textContainer}>
          <Grid xs={12} md={10} xl={10} className={classes.backgroundContainer}>
            <Grid container alignItems="center">
              <Grid>
                <h1>
                  <Translate>{data.product.name}</Translate>
                </h1>
              </Grid>
            </Grid>

            <Typography className={classes.description}>
              {parse(data.product.description)}
            </Typography>

            <Grid container className={classes.buttonContainer}>
              <Grid item>
                <Typography className={classes.price}>
                  <DuitenIcon />
                  <Typography component="span" className={classes.priceText}>
                    {data.product.price}
                  </Typography>
                </Typography>
              </Grid>
              {data.product.stockAmount > 0 && data.product.published && (
                <Grid item>
                  <AddToCartButton
                    className={classes.cartButton}
                    id={data.product.id}
                    price={data.product.price}
                    name={data.product.name}
                    img={data.product.images[0].location}
                  />
                </Grid>
              )}
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    );
  } else if (error) {
    return (
      <div>
        <h2>
          <Translate>Er ging iets fout, probeer het later opnieuw</Translate>
        </h2>
      </div>
    );
  } else if (loading) {
    return (
      <div>
        <CircularProgress color="primary" />
      </div>
    );
  } else {
    return (
      <div>
        <h2>
          <Translate>Er ging iets fout</Translate>
        </h2>
      </div>
    );
  }
}
